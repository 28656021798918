import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LhumosSpaces from "../spaces.json";
import { getDatasetsByCollectionId, getCollectionById, getChildCollectionsByCollectionId } from "./APImodules.js";
import LoadingCircle from "./LoadingCircle";
import CollectionPopup from "./CollectionPopup";
import { Database, ChevronDown, FolderTree } from "lucide-react";
import { extractCleanText, processTextContent } from './TextContentProcessor';

function CollectionContainer({ children, spaceIndex, isExpanded: parentIsExpanded, onToggleExpand: parentOnToggleExpand, isTopLevel }) {
  const [isLoading, setIsLoading] = useState(true);
  const [datasets, setDatasets] = useState([]);
  const [isPlaylist, setIsPlaylist] = useState(false);
  const [collections, setCollections] = useState([]);
  const [filteredDescription, setFilteredDescription] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [expandedCollections, setExpandedCollections] = useState(new Set());
  const theme = LhumosSpaces[spaceIndex];

  const COLLECTION_ID = children.id;

  useEffect(() => {
    setIsLoading(true);
    fetchCollectionData();
  }, [COLLECTION_ID]);

  const fetchCollectionData = async () => {
    try {
      const datasets = await fetchDatasets();
      const collections = await fetchCollections();
      setDatasets(datasets);
      setCollections(collections);
      setIsPlaylist(collections.length > 0);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching collection data:", error);
      setIsLoading(false);
    }
  };

  const fetchDatasets = async () => {
    const result = await getDatasetsByCollectionId(COLLECTION_ID, "test");
    if (!result) return [];
    
    const filteredResult = result.filter(dataset => !dataset.name.includes("[files]"));
    const collectionData = await getCollectionById(COLLECTION_ID);
    if (!collectionData) return filteredResult;
  
    const { cleanText, jsonContent } = processTextContent(collectionData.description);
    setFilteredDescription(cleanText);
  
    const orderData = jsonContent.order;
    return orderData || filteredResult;
  };

  const fetchCollections = async () => {
    const result = await getChildCollectionsByCollectionId(COLLECTION_ID, "test");
    return result || [];
  };

  const handleHeaderClick = (e) => {
    e.stopPropagation();
    if (isTopLevel) {
      parentOnToggleExpand();
    } else {
      const newExpandedState = !expandedCollections.has(COLLECTION_ID);
      setExpandedCollections(prev => {
        const newSet = new Set(prev);
        if (newExpandedState) {
          newSet.add(COLLECTION_ID);
        } else {
          newSet.delete(COLLECTION_ID);
        }
        return newSet;
      });
    }
  };

  const handleCollectionClick = (e) => {
    e.stopPropagation();
    setIsPopupOpen(true);
  };

  const handleNestedToggle = (collectionId) => {
    setExpandedCollections(prev => {
      const newSet = new Set(prev);
      if (newSet.has(collectionId)) {
        newSet.delete(collectionId);
      } else {
        newSet.add(collectionId);
      }
      return newSet;
    });
  };

  if (isLoading) return <LoadingCircle />;

  const containerWrapperClass = "w-full max-w-screen-2xl";
  const isCurrentlyExpanded = isTopLevel ? parentIsExpanded : expandedCollections.has(COLLECTION_ID);

  // For playlist collections (with subcollections)
  if (isPlaylist) {
    return (
      <div className={containerWrapperClass}>
        <div 
          className="mb-3 md:mb-8 rounded-lg overflow-hidden transition-all duration-300 hover:shadow-lg"
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url("https://clowder.eessi.science/fileThumbnail/${children.thumbnail}/blob")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            borderLeft: `4px solid ${theme.accent}`
          }}
        >
          <div className="p-2 md:p-8 cursor-pointer" onClick={handleHeaderClick}>
            <div className="flex justify-between items-center gap-2 md:gap-4">
              <h1 className="text-lg md:text-xl text-white font-semibold flex-grow truncate">
                {children.collectionname || children.name}
              </h1>
              <div className="flex items-center gap-1 md:gap-4 ml-2 md:ml-4">
                <span className="flex items-center gap-1 md:gap-2 bg-blue-400 bg-opacity-40 backdrop-blur-sm px-1.5 md:px-3 py-1 md:py-2 rounded text-white text-xs md:text-sm whitespace-nowrap">
                  <FolderTree size={14} className="md:w-5 md:h-5" />
                  {collections.length}
                </span>
                <span className="flex items-center gap-1 md:gap-2 bg-green-400 bg-opacity-40 backdrop-blur-sm px-1.5 md:px-3 py-1 md:py-2 rounded text-white text-xs md:text-sm whitespace-nowrap">
                  <Database size={14} className="md:w-5 md:h-5" />
                  {datasets.length}
                </span>
                <ChevronDown 
                  size={16}
                  className={`text-white transition-transform duration-300 md:w-6 md:h-6 ${isCurrentlyExpanded ? "rotate-180" : ""}`}
                />
              </div>
            </div>
            
            {filteredDescription && (
              <div className="mt-2 md:mt-4 backdrop-blur-sm rounded-lg p-2 md:p-4" style={{ backgroundColor: `${theme.color1}33` }}>
                <p className="text-white text-xs md:text-sm line-clamp-2">
                  {filteredDescription}
                </p>
              </div>
            )}
          </div>

          {isCurrentlyExpanded && (
            <div className="space-y-2 md:space-y-6 p-2 md:p-6 pt-0">
              {collections.map((collection, idx) => (
                <CollectionContainer 
                  key={collection.id} 
                  spaceIndex={spaceIndex} 
                  index={idx} 
                  isExpanded={expandedCollections.has(collection.id)}
                  onToggleExpand={() => handleNestedToggle(collection.id)}
                  isTopLevel={false}
                >
                  {collection}
                </CollectionContainer>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  // For regular collections (with videos)
  return (
    <div className={containerWrapperClass}>
      <div 
        className="mb-3 md:mb-8 rounded-lg overflow-hidden cursor-pointer transition-all duration-300 hover:shadow-lg"
        style={{ backgroundColor: theme.color2 }}
        onClick={handleCollectionClick}
      >
        <div
          className="relative"
          style={{ 
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url("https://clowder.eessi.science/fileThumbnail/${children.thumbnail}/blob")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            borderLeft: `4px solid ${theme.accent}`
          }}
        >
          <div className="p-2 md:p-8 flex gap-2 md:gap-4">
            <div className="flex-grow min-w-0">
              <div className="flex justify-between items-center mb-2 md:mb-4">
                <h3 className="text-base md:text-xl text-white font-semibold truncate pr-2">
                  {children.collectionname || children.name}
                </h3>
                <span className="flex items-center gap-1 md:gap-2 bg-green-400 bg-opacity-40 backdrop-blur-sm px-1.5 md:px-3 py-1 md:py-2 rounded text-white text-xs md:text-sm flex-shrink-0">
                  <Database size={14} className="md:w-5 md:h-5" />
                  {datasets.length}
                </span>
              </div>
              
              {filteredDescription && (
                <div className="backdrop-blur-sm rounded-lg p-2 md:p-4" style={{ backgroundColor: `${theme.color1}33` }}>
                  <p className="text-white text-xs md:text-sm line-clamp-2">{filteredDescription}</p>
                </div>
              )}
            </div>

            {datasets[0] && (
              <div className="relative w-32 md:w-56 h-20 md:h-32 flex-shrink-0 rounded-lg overflow-hidden group">
                <div 
                  className="absolute inset-0 bg-cover bg-center transform transition-transform duration-300 group-hover:scale-105"
                  style={{
                    backgroundImage: `url("https://clowder.eessi.science/fileThumbnail/${datasets[0].thumbnail}/blob")`
                  }}
                />
                <div 
                  className="absolute inset-0 flex items-end p-1 md:p-3"
                  style={{ background: 'linear-gradient(transparent, rgba(0,0,0,0.7))' }}
                >
                  <span className="text-white text-xs md:text-sm font-medium truncate md:whitespace-normal">
                    Latest: {datasets[0].name}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isPopupOpen && (
        <CollectionPopup
          collection={children}
          datasets={datasets}
          spaceIndex={spaceIndex}
          onClose={() => setIsPopupOpen(false)}
        />
      )}
    </div>
  );
}

export default CollectionContainer;