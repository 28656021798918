import React, { useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Slider from "react-slick";
import { X, ArrowLeft, ArrowRight, Image, FileText } from "lucide-react";
import ApiGet from "./APImodules.js";
import LoadingCircle from "./LoadingCircle.js";
import LhumosSpaces from "../spaces.json";
import Modal from './Modal';
import pdf from "../img/filetypes/pdf.png";
import download from "../img/download-arrow.png";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfContainer = ({ children, spaceIndex }) => {
  const FILE_ID = children.id;
  const theme = LhumosSpaces[spaceIndex];
  const sliderRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const [isImageView, setIsImageView] = useState(true);
  const [pdfMetadata, setPdfMetadata] = useState(null);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [previewArray, setPreviewArray] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await ApiGet(
        `https://clowder.eessi.science/api/files/${FILE_ID}/metadata.jsonld`
      );
      setPdfMetadata(result);
      setPreviewArray(
        result[0].content.preview_images.map(
          (image) => `https://clowder.eessi.science/api/previews/${image}`
        )
      );
      setIsLoading(false);
    };
    fetchData();
  }, [FILE_ID]);

  const sliderSettings = {
    infinite: false,
    vertical: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    beforeChange: (current, next) => setSlideIndex(next),
  };

  const handlePageChange = (direction) => {
    setPageNumber((prevPage) => {
      const totalPages = pdfMetadata[0].content.num_pages;
      const newPage = direction === 'next'
        ? (prevPage < totalPages ? prevPage + 1 : 1)
        : (prevPage === 1 ? totalPages : prevPage - 1);
      
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(newPage - 1);
      }
      return newPage;
    });
  };

  if (isLoading) return <LoadingCircle />;

  return (
    <>
      <div
        className="text-white py-2 px-4 rounded mt-4 cursor-pointer hover:bg-white/10 transition-colors"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)), url("https://clowder.eessi.science/api/previews/${pdfMetadata[0].content.preview_images[0]}")`,
          backgroundColor: theme.color2,
        }}
        onClick={() => setPdfViewerOpen(true)}
      >
        <div className="flex">
          <div className="pr-2 relative">
            <img src={pdf} className="h-10 w-10 ml-2" alt="PDF Icon" />
          </div>
          <div className="p-2 flex-initial w-40 break-words">
            <strong>{children.filename}</strong>
            <br />
            {pdfMetadata[0].content.num_pages} pages
            <br />
            {String(pdfMetadata[0].content.pdf_size_mb).substring(0, 5)} MB
          </div>
          <div className="flex-grow"></div>
          <div className="py-2 pr-2">
            <a
              href={`https://clowder.eessi.science/api/files/${children.id}/`}
              onClick={(e) => e.stopPropagation()}
            >
              <img src={download} className="h-8 mt-4" alt="Download Icon" />
            </a>
          </div>
        </div>
      </div>

      <Modal
        isOpen={pdfViewerOpen}
        onClose={() => setPdfViewerOpen(false)}
        contentClassName="w-[90vw] max-w-[1800px]"
        contentStyle={{ height: '90vh' }}
        style={{
          backgroundColor: theme.color2,
          borderLeft: `4px solid ${theme.accent}`
        }}
      >
        <div className="flex gap-6 h-full">
          <div className="w-48 flex-shrink-0">
            <Slider {...sliderSettings} ref={sliderRef}>
              {previewArray.map((url, idx) => (
                <div
                  key={idx}
                  onClick={() => {
                    setPageNumber(idx + 1);
                    setSlideIndex(idx);
                  }}
                  className="px-2 py-1"
                >
                  <div 
                    className={`relative rounded-lg overflow-hidden transition duration-200 ${
                      idx === slideIndex ? 'ring-2' : ''
                    }`}
                    style={{ 
                      ring: theme.accent 
                    }}
                  >
                    <img
                      src={url}
                      alt={`Page ${idx + 1}`}
                      className="w-full h-auto"
                    />
                    <div 
                      className="absolute top-2 left-2 w-8 h-8 rounded-full flex items-center justify-center text-sm font-medium"
                      style={{
                        backgroundColor: theme.accent
                      }}
                    >
                      {idx + 1}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>

          <div className="flex-1 flex flex-col min-w-0">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-xl font-semibold text-white">
                {children.filename}
              </h3>
              <button
                onClick={() => setIsImageView(!isImageView)}
                className="flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm text-white/90 hover:bg-white/10"
              >
                {isImageView ? (
                  <>
                    <FileText size={16} />
                    View as PDF
                  </>
                ) : (
                  <>
                    <Image size={16} />
                    View as Images
                  </>
                )}
              </button>
            </div>

            <div className="flex-grow bg-black/20 rounded-lg p-4 flex items-center justify-center">
              {isImageView ? (
                <div
                  className="w-full h-full bg-contain bg-no-repeat bg-center"
                  style={{
                    backgroundImage: `url("${previewArray[pageNumber - 1]}")`,
                  }}
                />
              ) : (
                <div className="h-full flex items-center justify-center">
                  {isPdfLoading && <LoadingCircle />}
                  <Document
                    file={`https://clowder.eessi.science/api/files/${children.id}/blob/`}
                    onLoadSuccess={() => setIsPdfLoading(false)}
                    loading={<LoadingCircle />}
                  >
                    <Page pageNumber={pageNumber} height={window.innerHeight * 0.7} />
                  </Document>
                </div>
              )}
            </div>

            <div className="flex items-center justify-center gap-4 mt-4">
              <button
                onClick={() => handlePageChange('prev')}
                className="p-2 rounded-lg hover:bg-white/10"
              >
                <ArrowLeft size={24} className="text-white" />
              </button>
              <span className="text-white">
                Page {pageNumber} of {pdfMetadata[0].content.num_pages}
              </span>
              <button
                onClick={() => handlePageChange('next')}
                className="p-2 rounded-lg hover:bg-white/10"
              >
                <ArrowRight size={24} className="text-white" />
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PdfContainer;