import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Database, Globe2, ArrowRight } from "lucide-react";
import Footer from "./components/Footer";
import LhumosSpaces from "./spaces.json";
import LoadingScreen from "./components/LoadingScreen";
import { getSpaces } from "./components/APImodules.js";
import atomPlanet from "./img/atom_planet.png";

function Spaces() {
  const [isLoading, setIsLoading] = useState(true);
  const [spaces, setSpaces] = useState([]);
  const [spacesStats, setSpacesStats] = useState({});

  useEffect(() => {
    async function fetchSpaces() {
      setIsLoading(true);
      const result = await getSpaces();
      const filteredSpaces = LhumosSpaces.map(lhumosSpace => {
        const matchingSpace = result.find(space => space.id === lhumosSpace.id);
        return matchingSpace ? { ...matchingSpace, ...lhumosSpace } : null;
      }).filter(Boolean);
      
      // Get stats from cached structure
      const stats = {};
      try {
        const cached = localStorage.getItem("lhumosStructure");
        if (cached) {
          const { treeData } = JSON.parse(cached);
          
          // Function to count collections and datasets
          const countItems = (node) => {
            let collections = 0;
            let datasets = 0;
            
            const traverse = (n) => {
              if (n.type === "collection") collections++;
              if (n.type === "dataset") datasets++;
              if (n.children) n.children.forEach(traverse);
            };
            
            traverse(node);
            return { collections, datasets };
          };
          
          // Count for each space
          treeData.children.forEach(spaceNode => {
            const spaceName = spaceNode.name;
            const matchingSpace = filteredSpaces.find(s => s.name === spaceName);
            if (matchingSpace) {
              stats[matchingSpace.id] = countItems(spaceNode);
            }
          });
        }
      } catch (error) {
        console.error("Error loading cached stats:", error);
      }
      
      setSpacesStats(stats);
      setSpaces(filteredSpaces);
      setIsLoading(false);
    }
    fetchSpaces();
  }, []);

  if (isLoading) return <LoadingScreen />;

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#4883b8] via-sky-900 to-black relative overflow-hidden">
      <img
        src={atomPlanet}
        alt="Atom Planet"
        className="absolute w-2/3 top-0 -right-40 opacity-20 rotating"
        style={{
          animation: "rotation 120s infinite linear",
          transformOrigin: "center center",
        }}
      />

      <style>
        {`
          @keyframes rotation {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(-360deg); }
          }
          
          @keyframes fadeIn {
            from { opacity: 0; transform: translateY(20px); }
            to { opacity: 1; transform: translateY(0); }
          }
          
          .space-card {
            animation: fadeIn 0.5s ease-out forwards;
            animation-delay: calc(var(--animation-order) * 0.1s);
            opacity: 0;
          }
          
          .space-card-gradient::after {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.3) 0%,
              rgba(0, 0, 0, 0.6) 100%
            );
            border-radius: 0.75rem;
            transition: all 0.3s ease;
          }
          
          .space-card:hover .space-card-gradient::after {
            background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.4) 0%,
              rgba(0, 0, 0, 0.8) 100%
            );
          }

          .space-card:hover .card-arrow {
            transform: translateX(4px);
          }
          
          .space-card:hover .card-bg {
            transform: scale(1.1);
          }
        `}
      </style>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {spaces.map((space, idx) => {
            const stats = spacesStats[space.id] || { collections: 0, datasets: 0 };
            
            return (
              <div
                key={space.id}
                className="space-card"
                style={{ '--animation-order': idx }}
              >
                <Link
                  to={`/spaces/${idx}`}
                  className="block group no-underline"
                >
                  <div className="relative h-[22rem] rounded-xl overflow-hidden shadow-lg transition-all duration-300 transform hover:-translate-y-1">
                    {/* Background Image with Gradient Overlay */}
                    <div 
                      className="card-bg absolute inset-0 space-card-gradient bg-cover bg-center transition-transform duration-700"
                      style={{
                        backgroundImage: `url(${space.bgurl})`
                      }}
                    />

                    {/* Content Container */}
                    <div className="relative h-full z-10 p-4 flex flex-col">
                      {/* Header */}
                      <div className="flex items-center space-x-3">
                        <div 
                          className="h-14 w-14 rounded-lg overflow-hidden shadow-lg transform transition-transform group-hover:scale-105"
                          style={{ 
                            borderLeft: `4px solid ${space.accent}`,
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            backdropFilter: 'blur(10px)'
                          }}
                        >
                          <img
                            src={space.iconurl}
                            alt={space.externalid}
                            className="h-full w-full object-cover"
                          />
                        </div>
                        <h2 className="text-xl font-bold text-white">
                          {space.externalid}
                        </h2>
                      </div>

                      {/* Description */}
                      <div className="mt-4 flex-grow">
                        <div 
                          className="backdrop-blur-sm bg-black/20 rounded-lg p-3 transition-colors group-hover:bg-black/30"
                          style={{ borderLeft: `4px solid ${space.accent}` }}
                        >
                          <p className="text-white/90 text-sm line-clamp-5">
                            {space.description}
                          </p>
                        </div>
                      </div>

                      {/* Footer Stats */}
                      <div className="mt-auto pt-3">
                        <div 
                          className="flex justify-between items-center rounded-lg py-2 px-3"
                          style={{ 
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            backdropFilter: 'blur(10px)',
                            borderLeft: `4px solid ${space.accent}`
                          }}
                        >
                          <div className="flex gap-3">
                            <div className="flex items-center gap-1 text-white/80">
                              <Database size={16} />
                              <span className="text-sm">{stats.collections} Collections</span>
                            </div>
                            <div className="flex items-center gap-1 text-white/80">
                              <Globe2 size={16} />
                              <span className="text-sm">{stats.datasets} Videos</span>
                            </div>
                          </div>
                          <ArrowRight 
                            className="text-white/80 card-arrow transition-transform duration-300"
                            size={20}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Spaces;