import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import LoadingScreen from "./components/LoadingScreen.js";
import "./Player.css";
import ApiGet from "./components/APImodules.js";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

let files = [];
let dataset = [
  {
    created_at: "loading...",
    description: "this video has no description.",
    content: { previews: "loading" },
  },
];

function PlayerEditor() {
  //STATES
  var loading = false;
  const [isLoading, setIsLoading] = useState(true);
  const [slides, setSlides] = useState([]);
  const [removedSlides, setRemovedSlides] = useState([]);
  const [playlist, setPlaylist] = useState([]);
  const [apiKey, setApiKey] = useState("");
  const [initialDescription, setInitialDescription] = useState("");
  const [injectedJson, setInjectedJson] = useState("");
  const [initialTitle, setInitialTitle] = useState("");

  //ONCHANGE FUNCTIONS
  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value);
  };
  const handleChangeDescription = (event) => {
    setInitialDescription(event.target.value);
  };
  const handleChangeTitle = (event) => {
    setInitialTitle(event.target.value);
  };

  //URL PARAMETERS
  let UrlParams = useParams();
  const SPACE_INDEX = UrlParams.spaceId;
  const VIDEO_NUMBER = UrlParams.videoNumber;
  const COLLECTION_ID = UrlParams.collectionId;
  const DATASET_ID = UrlParams.datasetId;

  const slider = useRef(null);

  //API CALLS
  useEffect(() => {
    setIsLoading(true);
    GetAllData();
  }, [loading]);

  async function GetAllData() {
    files = await ApiGet(
      `https://clowder.eessi.science/api/datasets/${DATASET_ID}/files`
    );
    var file_id = files[0].id;
    for (let i = 0; i < files.length; i++) {
      if (files[i].contentType === "video/mp4") {
        file_id = files[i].id;
      }
    }

    dataset = await ApiGet(
      `https://clowder.eessi.science/api/files/${file_id}/metadata.jsonld`
    );

    const slidesWithRemovedProp = dataset[0].content.listslides.map(
      (slide) => ({ ...slide, isRemoved: false })
    );

    setSlides(slidesWithRemovedProp);
    setRemovedSlides(slidesWithRemovedProp);
    //console.log(removedSlides);
    setPlaylist(
      await ApiGet(
        `https://clowder.eessi.science/api/collections/${COLLECTION_ID}/datasets`
      )
    );
    setIsLoading(false);
  }
  useEffect(() => {
    if (!isLoading) {
      const jsonString = playlist[VIDEO_NUMBER].description;
      const extractedJSON = extractJSONFromString(jsonString);

      if (extractedJSON !== null) {
        setRemovedSlides(extractedJSON);
      }
      fetchTitle();
    }
  }, [isLoading, playlist, VIDEO_NUMBER]);

  function extractJSONFromString(string) {
    const regex = /\[slides\](.*?)\[endslides\]/s;
    const match = string.match(regex);
    if (match && match[1]) {
      const json = match[1].trim();
      const remaining = string.replace(match[0], "");
      setInitialDescription(remaining);
      setInjectedJson(json);
      return JSON.parse(json);
    } else {
      setInitialDescription(string);
    }
    return null;
  }

  function fetchTitle() {
    const fetchedTitle = playlist[VIDEO_NUMBER].name;
    setInitialTitle(fetchedTitle);
  }

  const updateDatasetDescription = async () => {
    //UPDATE DESCRIPTION CALL
    const description =
      initialDescription +
      "[slides]" +
      JSON.stringify(removedSlides) +
      "[endslides]";
    const url = `https://clowder.eessi.science/api/datasets/${DATASET_ID}/description?key=${apiKey}`;

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ description }),
      });

      if (!response.ok) {
        toast.error("Failed to update");
        throw new Error("Failed to update dataset description");
      }

      toast.success("Updated successfully");
      console.log("Dataset description updated successfully");
      setIsLoading(true);
      GetAllData();
    } catch (error) {
      console.error(error);
    }
  };

  const updateDatasetTitle = async () => {
    //UPDATE TITLE CALL
    try {
      const url = `https://clowder.eessi.science/api/datasets/${DATASET_ID}/title?key=${apiKey}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: String(initialTitle) }),
      });

      if (!response.ok) throw new Error("Failed to update dataset title");

      console.log("Dataset title updated successfully");
      setIsLoading(true);
      GetAllData();
    } catch (error) {
      console.error(error);
    }
  };

  //slider carousel variables
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderSettings = {
    infinite: false,
    lazyLoad: false,
    speed: 300,
    slidesToShow: slides.length - 1,
    vertical: true,
    focusOnSelect: false,
    dots: false,
    centerPadding: 1,
    centerMode: false,
    beforeChange: (current, next) => setSlideIndex(next),
  };

  //HTML CODE
  if (isLoading) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <div className="m-auto px-4 min-h-screen bg-gradient-to-b from-[#4883b8] via-sky-900 to-black overflow-hidden">
      <ToastContainer />
      <div className="grid grid-cols-12 max-w-screen-xl m-auto">
        <div className="bg-sky-700 border-none rounded-3xl p-4 col-span-3 m-4">
          <Slider {...sliderSettings} ref={slider}>
            {removedSlides.map((img, idx) => (
              <div
                key={idx}
                className={`h-auto w-40 rounded-xl ${
                  idx === slideIndex ? "border-4 border-blue-600" : ""
                } cursor-pointer`}
                onClick={() => {
                  const newSlides = [...removedSlides];
                  newSlides[idx].isRemoved = !newSlides[idx].isRemoved;
                  setRemovedSlides(newSlides);
                }}
              >
                <div className="text-white rounded-lg w-10 h-10 flex justify-center items-center font-semibold p-3 absolute bg-blue-400">
                  {idx + 1}
                </div>
                <img
                  src={`https://clowder.eessi.science/api/previews/${img[2]}`}
                  alt={img[2]}
                  className="rounded-xl h-32 w-auto"
                  style={{ opacity: removedSlides[idx].isRemoved ? 0.4 : 1 }}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="col-span-9">
          <div className="bg-sky-700 text-white border-none rounded-3xl p-4 m-4">
            <h2>Video settings Dashboard</h2>
            <input
              type="password"
              className="rounded py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 w-96 mr-2 mb-4 bg-sky-800"
              placeholder="Enter your personal clowder API key" 
              value={apiKey}
              onChange={handleApiKeyChange}
            />
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                updateDatasetTitle();
                updateDatasetDescription();
              }}
            >
              Save & Apply
            </button>
            <h4>API IDs</h4>
            <div className="flex">
              <p className="bg-blue-400 text-white  py-2 px-4 rounded mr-2">
                <strong>Space N:</strong> {SPACE_INDEX}
              </p>
              <p className="bg-blue-400 text-white  py-2 px-4 rounded" mr-2>
                <strong>Collection id:</strong> {COLLECTION_ID}
              </p>
              <p className="bg-blue-400 text-white  py-2 px-4 rounded">
                <strong>Dataset id:</strong> {DATASET_ID}
              </p>
            </div>

            <h4>Content info</h4>
            <p>
              <strong>Video name:</strong>
              <input
                type="text"
                className=" rounded py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full mt-2 bg-sky-800"
                placeholder="video name" //key debug: 3692b523-c6d2-4c7b-834b-2a4ca52271b3
                value={initialTitle} //playlist[VIDEO_NUMBER].name;
                onChange={handleChangeTitle}
              />
            </p>
            <strong>Video description:</strong>
            <textarea
              type="text"
              className="rounded py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full mt-2 bg-sky-800"
              rows="3"
              placeholder="video description"
              value={initialDescription}
              onChange={handleChangeDescription}
            />
            <strong>Injected Json</strong>
            <textarea
              type="text"
              className="rounded py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full mt-2 bg-sky-800"
              rows="3"
              placeholder="injectedJson"
              value={injectedJson}
            />
            <div className="flex mt-2">
              <p className="bg-blue-400 text-white  py-2 px-4 rounded mr-2">
                <strong>Number of raw slides:</strong> {removedSlides.length}
              </p>
              <p className="bg-green-400 text-white py-2 px-4 rounded mr-2">
                <strong>Active slides:</strong>{" "}
                {
                  removedSlides.filter((slidelist) => !slidelist.isRemoved)
                    .length
                }
              </p>
              <p className="bg-red-400 text-white py-2 px-4 rounded mr-2">
                <strong>Hidden slides:</strong>{" "}
                {
                  removedSlides.filter((slidelist) => slidelist.isRemoved)
                    .length
                }
              </p>
            </div>
          </div>
          <div className="bg-sky-700 text-white border-none rounded-3xl p-4 m-4">
            <h2>Files</h2>

            <p className="bg-blue-400 text-white py-2 px-4 rounded mr-2">
              <strong>Number of files:</strong> {files.length}
            </p>
            {files.map((file, idx) => (
              <div key={idx}>
                {file.contentType === "application/pdf" ? (
                  <div className="bg-red-300 text-white py-2 px-4 rounded mt-4">
                    <strong>PDF</strong>
                    <br />
                    <strong>Name:</strong> {file.filename}
                    <br />
                    <strong>Description:</strong> {file.filedescription}
                    <br />
                    <strong>Id:</strong> {file.id}
                    <br />
                    <strong>Status:</strong> {file.status}
                    <br />
                    <strong>PDF preview:</strong>
                    <br />
                    <Document
                      file={`https://clowder.eessi.science/api/files/${file.id}/blob/`}
                    >
                      <Page pageNumber={1} className="rounded" />
                    </Document>
                  </div>
                ) : (
                  <div className="bg-sky-800 text-white py-2 px-4 rounded mt-4">
                    <strong>Type:</strong> {file.contentType}
                    <br />
                    <strong>Name:</strong> {file.filename}
                    <br />
                    <strong>Description:</strong> {file.filedescription}
                    <br />
                    <strong>Id:</strong> {file.id}
                    <br />
                    <strong>Status:</strong> {file.status}
                    <br />
                    {/* Add more non-PDF specific content here */}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerEditor;
