import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Database, ChevronDown, FolderTree } from 'lucide-react';
import { getDatasetsByCollectionId, getCollectionById, getChildCollectionsByCollectionId } from './APImodules.js';
import { extractCleanText, processTextContent } from './TextContentProcessor';
import LoadingCircle from './LoadingCircle';
import LhumosSpaces from '../spaces.json';

const CompactCollectionContainer = ({ 
  children, 
  spaceIndex, 
  isExpanded: parentIsExpanded, 
  onToggleExpand: parentOnToggleExpand,
  isTopLevel,
  onExpandSibling
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [datasets, setDatasets] = useState([]);
  const [childCollections, setChildCollections] = useState([]);
  const [filteredDescription, setFilteredDescription] = useState('');
  const [isPlaylist, setIsPlaylist] = useState(false);
  const [expandedChildId, setExpandedChildId] = useState(null);
  
  const theme = LhumosSpaces[spaceIndex];
  const COLLECTION_ID = children.id;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [datasetsResult, collectionsResult, collectionData] = await Promise.all([
          getDatasetsByCollectionId(COLLECTION_ID, "test"),
          getChildCollectionsByCollectionId(COLLECTION_ID, "test"),
          getCollectionById(COLLECTION_ID)
        ]);

        const filteredDatasets = datasetsResult.filter(dataset => 
          !dataset.name.includes("[files]")
        );
        setDatasets(filteredDatasets);
        setChildCollections(collectionsResult || []);
        setIsPlaylist(collectionsResult && collectionsResult.length > 0);

        if (collectionData?.description) {
          const { cleanText } = processTextContent(collectionData.description);
          setFilteredDescription(cleanText);
        }
      } catch (error) {
        console.error("Error fetching collection data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [COLLECTION_ID]);

  useEffect(() => {
    if (!parentIsExpanded && !isTopLevel) {
      setExpandedChildId(null);
    }
  }, [parentIsExpanded, isTopLevel]);

  const handleHeaderClick = (e) => {
    e.stopPropagation();
    if (isTopLevel) {
      if (onExpandSibling) {
        onExpandSibling(COLLECTION_ID);
      } else {
        parentOnToggleExpand();
      }
    } else {
      const newExpandedState = !isCurrentlyExpanded;
      setExpandedChildId(newExpandedState ? COLLECTION_ID : null);
    }
  };

  if (isLoading) return <LoadingCircle />;

  const isCurrentlyExpanded = isTopLevel ? parentIsExpanded : COLLECTION_ID === expandedChildId;

  const thumbnailStyle = {
    backgroundImage: `url("https://clowder.eessi.science/fileThumbnail/${children.thumbnail}/blob")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  };

  const overlayStyle = {
    backdropFilter: "blur(8px) brightness(0.6)",
    WebkitBackdropFilter: "blur(8px) brightness(0.6)",
    backgroundColor: "rgba(0, 0, 0, 0.2)"
  };

  return (
    <div className={`transition-all duration-300 ease-in-out ${
      isCurrentlyExpanded ? 'col-span-full w-full' : 'col-span-1'
    }`}>
      <div 
        className="mt-4 rounded-lg overflow-hidden cursor-pointer transition-all duration-300 hover:shadow-lg h-full focus-visible:outline-none focus-visible:ring-2 focus:ring-offset-2"
        onClick={handleHeaderClick}
        role="button"
        tabIndex={0}
        aria-expanded={isCurrentlyExpanded}
      >
        <div
          className="relative h-full"
          style={{ 
            ...thumbnailStyle,
            borderLeft: `4px solid ${theme.accent}`
          }}
        >
          <div className="relative h-full" style={overlayStyle}>
            {!isCurrentlyExpanded ? (
              <div className="p-4 h-full flex flex-col justify-between">
                <div className="flex justify-between items-start gap-2">
                  <h3 className="text-lg text-white font-semibold leading-tight">
                    {children.name || children.collectionname}
                  </h3>
                  {isPlaylist ? (
                    <span className="flex items-center gap-1 bg-blue-400 bg-opacity-40 backdrop-blur-sm px-2 py-1 rounded text-white text-sm flex-shrink-0">
                      <FolderTree size={16} aria-hidden="true" />
                      <span className="sr-only">Number of collections:</span>
                      {childCollections.length}
                    </span>
                  ) : (
                    <span className="flex items-center gap-1 bg-green-400 bg-opacity-40 backdrop-blur-sm px-2 py-1 rounded text-white text-sm flex-shrink-0">
                      <Database size={16} aria-hidden="true" />
                      <span className="sr-only">Number of datasets:</span>
                      {datasets.length}
                    </span>
                  )}
                </div>
                {filteredDescription && (
                  <p className="text-white/90 text-sm line-clamp-2 mt-2">{filteredDescription}</p>
                )}
              </div>
            ) : (
              <div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-lg text-white font-semibold truncate pr-2">
                      {children.name || children.collectionname}
                    </h3>
                    {isPlaylist ? (
                      <div className="flex items-center gap-2">
                        <span className="flex items-center gap-1 bg-blue-400 bg-opacity-40 backdrop-blur-sm px-2 py-1 rounded text-white text-sm">
                          <FolderTree size={16} aria-hidden="true" />
                          {childCollections.length}
                        </span>
                        <ChevronDown 
                          size={20} 
                          className="text-white transition-transform rotate-180"
                          aria-hidden="true"
                        />
                      </div>
                    ) : (
                      <span className="flex items-center gap-1 bg-green-400 bg-opacity-40 backdrop-blur-sm px-2 py-1 rounded text-white text-sm flex-shrink-0">
                        <Database size={16} aria-hidden="true" />
                        {datasets.length}
                      </span>
                    )}
                  </div>
                  
                  {filteredDescription && (
                    <div className="backdrop-blur-sm rounded-lg p-2 bg-black/20">
                      <p className="text-white text-sm">{filteredDescription}</p>
                    </div>
                  )}
                </div>

                <div className="p-4 pt-0" onClick={e => e.stopPropagation()}>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                    {isPlaylist ? (
                      childCollections.map((collection) => (
                        <CompactCollectionContainer
                          key={collection.id}
                          spaceIndex={spaceIndex}
                          isExpanded={collection.id === expandedChildId}
                          onToggleExpand={() => setExpandedChildId(collection.id === expandedChildId ? null : collection.id)}
                          isTopLevel={false}
                        >
                          {collection}
                        </CompactCollectionContainer>
                      ))
                    ) : (
                      datasets.map((dataset, idx) => (
                        <Link
                          key={dataset.id}
                          to={`/player/${spaceIndex}/${idx}/${COLLECTION_ID}/${dataset.id}`}
                          className="group block focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          <div className="relative h-32 rounded-lg overflow-hidden">
                            <div 
                              style={{
                                ...thumbnailStyle,
                                backgroundImage: `url(https://clowder.eessi.science/fileThumbnail/${dataset.thumbnail}/blob)`
                              }}
                              className="absolute inset-0 transform transition-transform duration-300 group-hover:scale-105"
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent">
                              <div className="absolute bottom-0 left-0 right-0 p-3">
                                <p className="text-white text-sm font-medium truncate">
                                  {dataset.name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompactCollectionContainer;