import React, { useEffect } from 'react';
import { X } from 'lucide-react';

const Modal = ({ 
  isOpen, 
  onClose, 
  children,
  className = "",
  containerClassName = "",
  contentClassName = "",
  backdropClassName = "",
  style = {},
  contentStyle = {}
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'unset';
      };
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div 
      className={`fixed inset-0 z-50 overflow-y-auto ${backdropClassName}`}
      onClick={onClose}
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(2px)'
      }}
    >
      <div className={`min-h-screen px-4 py-8 flex items-center justify-center ${containerClassName}`}>
        <div className={`relative w-full max-w-7xl mx-auto ${contentClassName}`} style={contentStyle}>
          <button
            onClick={onClose}
            className="absolute -top-4 -right-4 text-white/90 p-2 rounded-full hover:bg-black/40 bg-black/20 backdrop-blur-sm z-10"
          >
            <X size={24} />
          </button>
          
          <div 
            className={`rounded-xl shadow-lg p-6 relative ${className}`}
            onClick={e => e.stopPropagation()}
            style={style}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;