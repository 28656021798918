// Import the spaces data
import spacesData from '../spaces.json';

// APImodules.js

const BASE_URL = 'https://clowder.eessi.science/api';
const API_KEY = '3e3832c8-95e7-4612-9aee-8f48cc30d21e';

// GET
async function ApiGet(url, options = {}) {
  const defaultOptions = {
    headers: {
      'X-API-Key': API_KEY,
    },
  };
  const mergedOptions = { ...defaultOptions, ...options };

  const resp = await fetch(url, mergedOptions);
  const json = await resp.json();
  localStorage.setItem(url, JSON.stringify({ data: json }));
  return json;
}

//search
export async function search(query, resourceType = null, datasetId = null, collectionId = null, spaceId = null, folderId = null, field = null, tag = null, from = null, size = null, page = null, sort = null, order = null) {
  let url = `${BASE_URL}/search?query=${encodeURIComponent(query)}`;
  if (resourceType) url += `&resource_type=${resourceType}`;
  if (datasetId) url += `&datasetid=${datasetId}`;
  if (collectionId) url += `&collectionid=${collectionId}`;
  if (spaceId) url += `&spaceid=${spaceId}`;
  if (folderId) url += `&folderid=${folderId}`;
  if (field) url += `&field=${field}`;
  if (tag) url += `&tag=${tag}`;
  if (from) url += `&from=${from}`;
  if (size) url += `&size=${size}`;
  if (page) url += `&page=${page}`;
  if (sort) url += `&sort=${sort}`;
  if (order) url += `&order=${order}`;
  const response = await ApiGet(url);
  console.log('Search API response:', response);
  return response;
}

//full structure

export async function getLhumosStructure() {
  try {
    const allSpaces = await ApiGet(`${BASE_URL}/spaces`);
    console.log('All spaces from API:', allSpaces);

    const filteredSpaces = spacesData.map(definedSpace => {
      const apiSpace = allSpaces.find(space => space.id === definedSpace.id);
      return apiSpace || { 
        id: definedSpace.id, 
        name: definedSpace.externalid || 'Unnamed Space',
        group: 'space'
      };
    });

    console.log('Filtered Spaces:', filteredSpaces);

    const structuredData = await Promise.all(filteredSpaces.map(async (space) => {
      try {
        const collections = await ApiGet(`${BASE_URL}/spaces/${space.id}/collections`);
        console.log(`Collections for Space ${space.id}:`, collections);

        const collectionsWithDatasets = await Promise.all(collections.map(async (collection) => {
          try {
            return await fetchCollectionData(collection.id);
          } catch (error) {
            console.warn(`Unauthorized or error fetching collection ${collection.id}:`, error);
            return {
              id: collection.id,
              name: 'Unauthorized Collection',
              group: 'unauthorized-collection',
              children: []
            };
          }
        }));

        console.log(`Structured Data for Space ${space.id}:`, collectionsWithDatasets);

        return {
          id: space.id,
          name: space.name, 
          group: 'space',
          children: collectionsWithDatasets
        };
      } catch (error) {
        console.error(`Error fetching data for space ${space.id}:`, error);
        return {
          id: space.id, 
          name: space.name || 'Unauthorized Space',
          group: 'unauthorized-space',
          children: []
        };
      }
    }));

    console.log('Final Structured Data:', structuredData);

    console.log('Before sorting:', structuredData.map(space => ({ id: space.id, name: space.name, childrenCount: space.children ? space.children.length : 0 })));

    const sortedStructuredData = structuredData.sort((a, b) => a.name.localeCompare(b.name));

    console.log('After sorting:', sortedStructuredData.map(space => ({ id: space.id, name: space.name, childrenCount: space.children ? space.children.length : 0 })));

    return sortedStructuredData;

  } catch (error) {
    console.error('Error fetching Lhumos structure:', error);
    throw error;
  }
}


// Files
export async function getFilesByDatasetId(datasetId) {
  return await ApiGet(`${BASE_URL}/datasets/${datasetId}/files`);
}

export async function getFileMetadataById(fileId) {
  return await ApiGet(`${BASE_URL}/files/${fileId}/metadata.jsonld`);
}

export function getFileBlobById(fileId) {
  return `${BASE_URL}/files/${fileId}/blob/`;
}

// Datasets
export async function getDatasetsByCollectionId(collectionId, key) {
  return await ApiGet(`${BASE_URL}/collections/${collectionId}/datasets/?key=${key}`);
}

export async function getDatasetsBySpaceId(spaceId) {
  return await ApiGet(`${BASE_URL}/spaces/${spaceId}/datasets`);
}

export async function getDatasetMetadataByFileId(fileId) {
  return await ApiGet(`${BASE_URL}/files/${fileId}/metadata.jsonld`);
}

// Collections

async function fetchCollectionData(collectionId) {
  const collectionData = await ApiGet(`${BASE_URL}/collections/${collectionId}`);
  const datasets = await ApiGet(`${BASE_URL}/collections/${collectionId}/datasets`);
  const childCollections = await ApiGet(`${BASE_URL}/collections/${collectionId}/getChildCollections`);

  const collectionName = collectionData.collectionname || collectionData.name || 'Unnamed Collection';

  const childCollectionsData = await Promise.all(childCollections.map(childCollection => fetchCollectionData(childCollection.id)));

  return {
    id: collectionData.id,
    name: collectionName,
    description: collectionData.description,
    thumbnail: collectionData.thumbnail,
    group: 'collection',
    children: [
      ...datasets.map(dataset => ({
        id: dataset.id,
        name: dataset.name,
        group: 'dataset'
      })),
      ...childCollectionsData
    ]
  };
}

export async function getCollectionById(collectionId) {
  return await ApiGet(`${BASE_URL}/collections/${collectionId}`);
}

export async function getCollectionsBySpaceId(spaceId) {
  return await ApiGet(`${BASE_URL}/spaces/${spaceId}/collections`);
}

export async function getChildCollectionsByCollectionId(collectionId, key) {
  return await ApiGet(`${BASE_URL}/collections/${collectionId}/getChildCollections/?key=${key}`);
}

// Spaces
export async function getSpaceById(spaceId) {
  return await ApiGet(`${BASE_URL}/spaces/${spaceId}`);
}

export async function getSpaces(limit = 64) {
  return await ApiGet(`${BASE_URL}/spaces?limit=${limit}`);
}

export async function getCollectionsForSpace(spaceId) {
  return await ApiGet(`${BASE_URL}/spaces/${spaceId}/collections`);
}

export default ApiGet;