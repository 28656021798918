import React, { useEffect, useState, useCallback, useMemo } from "react";
import { getLhumosStructure } from "./components/APImodules.js";
import Tree from "react-d3-tree";
import LoadingScreen from "./components/LoadingScreen";
import { useParams } from "react-router-dom";
import LhumosSpaces from "./spaces.json";
import {
  RefreshCw,
  Map,
  FolderTree,
  Database,
  ZoomIn,
  ZoomOut,
  X,
  ChevronRight,
} from "lucide-react";
import { truncateText } from "./components/TextTruncation";

const getNodeColor = (nodeDatum) => {
  if (nodeDatum.name === "Lhumos") return "#d8b4fe";
  if (nodeDatum.type === "unauthorized") return "#fdba74";
  if (nodeDatum.children && nodeDatum.children.length > 0) return "#93c5fd";
  if (nodeDatum.type === "dataset") return "#86efac";
  if (nodeDatum.type === "error") return "#ef4444";
  return "#fde047";
};

const DetailsSidebar = ({ node, onClose }) => {
  if (!node) return null;

  // Function to count collections and datasets for summary
  const countItems = (node) => {
    let collections = 0;
    let datasets = 0;
    const traverse = (node) => {
      if (node.type === "collection") collections++;
      if (node.type === "dataset") datasets++;
      if (node.children) node.children.forEach(traverse);
    };
    traverse(node);
    return { collections, datasets };
  };

  const { collections, datasets } = countItems(node);

  return (
    <div className="fixed right-0 top-0 w-64 h-full bg-white bg-opacity-50 backdrop-blur-md shadow-lg p-4 overflow-y-auto">
      {/* Close Button */}
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-gray-300 hover:text-white transition-colors"
        aria-label="Close Sidebar"
      >
        <X size={20} />
      </button>

      {/* Node Summary */}
      <div className="mb-4 text-gray-800">
        <h2 className="text-lg font-semibold mb-1">{node.name}</h2>
        {node.description && (
          <p className="text-gray-500 text-sm leading-tight">
            {node.description}
          </p>
        )}
      </div>

      {/* Counts for Collections and Datasets */}
      <div className="space-y-2">
        <div className="flex items-center justify-between px-3 py-2 rounded-lg bg-blue-100 bg-opacity-40">
          <FolderTree size={18} className="text-blue-600" />
          <span className="text-sm text-gray-700">Collections</span>
          <span className="font-semibold text-blue-600">{collections}</span>
        </div>

        <div className="flex items-center justify-between px-3 py-2 rounded-lg bg-green-100 bg-opacity-40">
          <Database size={18} className="text-green-600" />
          <span className="text-sm text-gray-700">Datasets</span>
          <span className="font-semibold text-green-600">{datasets}</span>
        </div>
      </div>

      {/* Collapsible Section for Detailed Info */}
      {node.type === "space" && (
        <div className="mt-4">
          <details className="group">
            <summary className="cursor-pointer text-gray-600 hover:text-gray-800 transition-colors">
              <h3 className="text-base font-semibold">Space Details</h3>
            </summary>
            <p className="mt-2 text-sm text-gray-500 leading-tight">
              This space contains various collections and datasets. Click to
              explore.
            </p>
          </details>
        </div>
      )}

      {/* Unauthorized or Error Messages */}
      {node.type === "unauthorized" && (
        <div className="mt-4 px-3 py-2 bg-red-100 bg-opacity-40 text-red-700 rounded-lg">
          Unauthorized access. Contact support.
        </div>
      )}
      {node.type === "error" && (
        <div className="mt-4 px-3 py-2 bg-red-100 bg-opacity-40 text-red-700 rounded-lg">
          Error loading data. Try again later.
        </div>
      )}
    </div>
  );
};

const Breadcrumbs = ({ node, onNodeClick }) => {
  const getPath = (node) => {
    const path = [];
    let current = node;
    while (current) {
      path.unshift(current);
      current = current.parent;
    }
    // Add the root node and all intermediate nodes
    const rootNode = { id: "root", name: "Lhumos" };
    const intermediatePath = [];
    let parentNode = path[0].parent;
    while (parentNode) {
      intermediatePath.unshift(parentNode);
      parentNode = parentNode.parent;
    }
    const fullPath = [rootNode, ...intermediatePath, ...path];
    return fullPath;
  };

  if (!node) {
    return null;
  }

  const path = getPath(node);

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {path.map((nodeItem, index) => (
          <li key={nodeItem.id} className="inline-flex items-center">
            {index > 0 && <span className="text-white mx-2">&gt;</span>}
            <button
              onClick={() => onNodeClick(nodeItem)}
              className="inline-flex items-center text-sm font-medium text-white hover:text-blue-200"
            >
              {nodeItem.name}
            </button>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default function LhumosMap() {
  const [isLoading, setIsLoading] = useState(true);
  const [treeData, setTreeData] = useState(null);
  const [zoom, setZoom] = useState(1);
  const { spaceId } = useParams();
  const [stats, setStats] = useState({
    spaceCount: 0,
    collectionCount: 0,
    datasetCount: 0,
  });
  const [selectedNode, setSelectedNode] = useState(null);
  const [translate, setTranslate] = useState({ x: 0, y: 200 });
  const [breadcrumbPath, setBreadcrumbPath] = useState([]);

  const dimensions = useMemo(() => {
    const sidebarWidth = 320; // Width of the sidebar
    return {
      width: window.innerWidth - sidebarWidth - 1000, // Adjust width to account for sidebar
      height: window.innerHeight - 200,
    };
  }, []);

  const gradientColors = useMemo(() => {
    const spaceDetails = LhumosSpaces.find((s) => s.id === spaceId) || {
      color1: "#4883b8",
      color2: "#4883b8",
    };
    return spaceDetails;
  }, [spaceId]);

  const processChildren = useCallback((children, stats) => {
    return children.map((child) => {
      if (child.group === "space") {
        const spaceDetails = LhumosSpaces.find(
          (space) => space.id === child.id
        );
        return {
          name: spaceDetails?.name || child.name,
          children: child.children
            ? processChildren(child.children, stats)
            : [],
          type: "space",
          id: child.id,
        };
      } else if (
        child.group === "collection" ||
        child.group === "unauthorized-collection"
      ) {
        stats.collectionCount++;
        return {
          name: child.name || "Unnamed Collection",
          description: child.description,
          children: processChildren(child.children, stats),
          type:
            child.group === "unauthorized-collection"
              ? "unauthorized"
              : "collection",
          id: child.id,
        };
      } else if (child.group === "dataset") {
        stats.datasetCount++;
        return {
          name: child.name,
          type: "dataset",
          id: child.id,
        };
      }
      return {
        name: "Unauthorized Space",
        type: "unauthorized",
      };
    });
  }, []);

  const fetchGraphData = useCallback(async () => {
    setIsLoading(true);
    const stats = { spaceCount: 0, collectionCount: 0, datasetCount: 0 };

    try {
      const lhumosStructure = await getLhumosStructure();
      stats.spaceCount = lhumosStructure.length;

      const root = {
        name: "Lhumos",
        children: lhumosStructure.map((space) => ({
          name: space.name,
          children: processChildren(space.children, stats),
        })),
      };

      setTreeData(root);
      setStats(stats);
      localStorage.setItem(
        "lhumosStructure",
        JSON.stringify({ treeData: root, stats })
      );
    } catch (error) {
      console.error("Error fetching Lhumos structure:", error);
      setTreeData({
        name: "Error Loading Lhumos Structure",
        type: "error",
      });
    }

    setIsLoading(false);
  }, [processChildren]);

  useEffect(() => {
    try {
      const cached = localStorage.getItem("lhumosStructure");
      if (cached) {
        const { treeData, stats } = JSON.parse(cached);
        setTreeData(treeData);
        setStats(stats);
        setIsLoading(false);
      } else {
        fetchGraphData();
      }
    } catch (error) {
      console.error("Error loading cached data:", error);
      fetchGraphData();
    }
  }, [fetchGraphData]);

  const handleNodeClick = (nodeDatum, evt) => {
    setSelectedNode(nodeDatum);

    // Update breadcrumb path
    const getPath = (node) => {
      const path = [];
      let current = node;
      while (current) {
        path.unshift(current);
        current = current.parent;
      }
      return path;
    };
    setBreadcrumbPath(getPath(nodeDatum));

    // Assuming these dimensions account for the current zoom level if necessary
    const nodeWidth = 400; // Adjust based on your node size
    const nodeHeight = 100; // Adjust based on your node size

    // Calculate the new position based on node coordinates and the container size
    const newX = dimensions.width / 2 - (nodeDatum.x + nodeWidth / 2);
    const newY = dimensions.height / 2 - (nodeDatum.y + nodeHeight / 2);

    // Only set new translate if it's different from the current state
    if (translate.x !== newX || translate.y !== newY) {
      setTranslate({ x: newX, y: newY });
    }
  };

  const renderCustomNode = ({ nodeDatum, toggleNode }) => (
    <g>
      <circle
        r={20}
        fill={getNodeColor(nodeDatum)}
        stroke={nodeDatum === selectedNode ? "#3b82f6" : "white"}
        strokeWidth={nodeDatum === selectedNode ? "6" : "4"}
        onClick={(evt) => {
          toggleNode();
          handleNodeClick(nodeDatum, evt);
        }}
        className="cursor-pointer hover:stroke-blue-200 transition-colors duration-200"
      />
      <text
        fill="white"
        strokeWidth="1"
        stroke="white"
        fontSize={16}
        x={30}
        dy=".31em"
        className="select-none"
      >
        {truncateText(nodeDatum.name, 40)}
      </text>
      {nodeDatum.description && (
        <text
          fill="white"
          strokeWidth="1"
          stroke="white"
          fontSize={12}
          x={30}
          dy="2em"
          className="select-none"
        >
          {truncateText(nodeDatum.description, 60)}
        </text>
      )}
    </g>
  );

  const handleZoom = (direction) => {
    setZoom((prevZoom) => {
      const newZoom = direction === "in" ? prevZoom * 1.2 : prevZoom / 1.2;
      return Math.min(Math.max(0.1, newZoom), 2);
    });
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div
      className="min-h-screen relative overflow-hidden"
      style={{
        backgroundImage: `linear-gradient(to bottom, ${gradientColors.color1}, ${gradientColors.color2})`,
      }}
    >
      <div
        className={`mx-8 max-w-screen-xl m-auto ${selectedNode ? "mr-80" : ""}`}
      >
        <div className="relative z-10 p-2" style={{ height: "80vh" }}>
          <div className="text-white mb-4 flex justify-between items-center">
            <div className="text-xl font-bold flex items-center space-x-4">
              <span>Interactive Data Map</span>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleZoom("out")}
                  className="bg-white text-blue-500 p-1 rounded-full hover:bg-blue-100 transition-colors duration-200"
                  title="Zoom Out"
                >
                  <ZoomOut size={20} />
                </button>
                <button
                  onClick={() => handleZoom("in")}
                  className="bg-white text-blue-500 p-1 rounded-full hover:bg-blue-100 transition-colors duration-200"
                  title="Zoom In"
                >
                  <ZoomIn size={20} />
                </button>
              </div>
            </div>
            <div className="flex space-x-4 items-center">
              <span className="bg-blue-300 text-blue-800 px-2 py-1 rounded flex items-center">
                <Map size={16} className="mr-1" /> {stats.spaceCount} Spaces
              </span>
              <span className="bg-green-300 text-green-800 px-2 py-1 rounded flex items-center">
                <FolderTree size={16} className="mr-1" />{" "}
                {stats.collectionCount} Collections
              </span>
              <span className="bg-yellow-300 text-yellow-800 px-2 py-1 rounded flex items-center">
                <Database size={16} className="mr-1" /> {stats.datasetCount}{" "}
                Datasets
              </span>
              <button
                onClick={fetchGraphData}
                className="bg-white text-blue-500 p-1 rounded-full hover:bg-blue-100 transition-colors duration-200"
                title="Refresh"
              >
                <RefreshCw size={20} />
              </button>
            </div>
          </div>

          <div className="text-white text-sm mb-2 bg-black bg-opacity-50 p-2 rounded">
            Click nodes to view details and focus. Click and drag to pan, scroll
            to zoom.
          </div>
                    {/* Breadcrumbs */}
                    <div className="mb-2">
            {selectedNode && (
              <Breadcrumbs node={selectedNode} onNodeClick={handleNodeClick} />
            )}
          </div>
          <div style={{ width: "100%", height: "calc(100% - 80px)" }}>
            <Tree
              data={treeData}
              orientation="horizontal"
              renderCustomNodeElement={renderCustomNode}
              pathFunc="curve"
              separation={{ siblings: 1, nonSiblings: 2 }}
              translate={translate} // Ensure this remains constant
              dimensions={dimensions}
              nodeSize={{ x: 400, y: 100 }}
              zoomable={true} // Disable zooming
              collapsible={true}
              initialDepth={1}
              pathClassFunc={() => "stroke-white stroke-1"}
              onNodeClick={handleNodeClick} // You can keep this for custom behavior
            />
          </div>{" "}
          <DetailsSidebar
            node={selectedNode}
            onClose={() => setSelectedNode(null)}
          />
        </div>
      </div>
    </div>
  );
}
