import React, { useState, useRef } from "react";
import { Link, useLocation, matchPath } from "react-router-dom";
import { Menu, X } from "lucide-react";
import LhumosSpaces from "../spaces.json";
import Login from "./Login";
import Backdrop from "./Backdrop";
import SearchResults from "./SearchResults";

import logo from "../img/logo.png";
import lens from "../img/zoom-lens.png";
import compass from "../img/compass.png";
import web from "../img/web.png";

function Navbar() {
  const [loginIsOpen, setLoginIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const searchInputRef = useRef(null);

  const location = useLocation();
  let match = matchPath({ path: "/spaces/:id" }, location.pathname);
  let SPACE_INDEX = match?.params.id;

  if (SPACE_INDEX == null) {
    match = matchPath(
      { path: "/player/:id/:videoid/:collectionid/:datasetid" },
      location.pathname
    );
    SPACE_INDEX = match?.params.id;
  }

  const spaceColor1 = SPACE_INDEX ? LhumosSpaces[SPACE_INDEX]?.color1 || "" : "#4883b8";
  const spaceColor2 = SPACE_INDEX ? LhumosSpaces[SPACE_INDEX]?.color2 || "" : "#5190c6";
  const spaceColor3 = SPACE_INDEX ? LhumosSpaces[SPACE_INDEX]?.accent || "" : "#63a1ff";

  const handleSearch = () => {
    if (searchQuery.trim()) setShowSearchResults(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") handleSearch();
  };

  return (
    <header>
      {loginIsOpen && (
        <>
          <Login onClick={() => setLoginIsOpen(false)} />
          <Backdrop onClick={() => setLoginIsOpen(false)} />
        </>
      )}
      <nav className="sticky top-0 z-50" style={{ backgroundColor: spaceColor1 }}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <Link to="/" className="flex-shrink-0 no-underline">
              <img src={logo} className="h-8 w-auto" alt="logo" />
            </Link>

            <div className="hidden md:flex items-center space-x-4">
              <Link to="/spaces" className="flex items-center text-white hover:opacity-80 transition-opacity no-underline">
                <img src={web} alt="spaces" className="w-5 h-5 mr-2" />
                <span>Spaces</span>
              </Link>
              <Link to="/map" className="flex items-center text-white hover:opacity-80 transition-opacity no-underline">
                <img src={compass} alt="Map" className="w-5 h-5 mr-2" />
                <span>Map</span>
              </Link>
              
              <div className="relative">
                <input
                  ref={searchInputRef}
                  type="text"
                  className={`pl-10 pr-4 py-2 rounded-full focus:outline-none transition-all duration-300 ${
                    isSearchFocused ? "w-64" : "w-48"
                  }`}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={handleKeyPress}
                  onFocus={() => setIsSearchFocused(true)}
                  onBlur={() => setIsSearchFocused(false)}
                  style={{
                    backgroundColor: spaceColor2,
                    color: "white",
                  }}
                  placeholder="Search..."
                />
                <img
                  src={lens}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 opacity-60 cursor-pointer"
                  alt="search"
                  onClick={handleSearch}
                />
              </div>
            </div>

            <button
              className="md:hidden p-2 rounded-md text-white hover:bg-white/10 transition-colors"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>

          {isMobileMenuOpen && (
            <div className="md:hidden py-4">
              <div className="space-y-4">
                <div className="relative">
                  <input
                    type="text"
                    className="w-full pl-10 pr-4 py-2 rounded-full focus:outline-none"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyPress={handleKeyPress}
                    style={{
                      backgroundColor: spaceColor2,
                      color: "white",
                    }}
                    placeholder="Search..."
                  />
                  <img
                    src={lens}
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 opacity-60 cursor-pointer"
                    alt="search"
                    onClick={handleSearch}
                  />
                </div>

                <Link 
                  to="/spaces" 
                  className="flex items-center text-white py-2 hover:bg-white/10 rounded-md px-3 transition-colors no-underline"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <img src={web} alt="spaces" className="w-5 h-5 mr-2" />
                  <span>Spaces</span>
                </Link>
                <Link 
                  to="/map" 
                  className="flex items-center text-white py-2 hover:bg-white/10 rounded-md px-3 transition-colors no-underline"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <img src={compass} alt="Map" className="w-5 h-5 mr-2" />
                  <span>Map</span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </nav>
      
      {showSearchResults && (
        <SearchResults
          query={searchQuery}
          onClose={() => setShowSearchResults(false)}
          spaceColor1={spaceColor1}
          spaceColor2={spaceColor2}
          spaceColor3={spaceColor3}
        />
      )}
    </header>
  );
}

export default Navbar;