import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer";

import Cecamlogo from "./img/cecamlogo.png";
import Marvellogo from "./img/marvellogo.png";
import Maxlogo from "./img/maxlogo.png";
import DomeLogo from "./img/domelogo.png";
import MultiLogo from "./img/multilogo.png";
import atomPlanet from "./img/atom_planet.png";
import playicon from "./img/playicon.png";
import compass from "./img/compass.png";

toast.info("Welcome to the beta version of the Lhumos training portal!");

function Home() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#4883b8] via-sky-900 to-black relative overflow-hidden">
      <ToastContainer />

      <img
        src={atomPlanet}
        alt="Atom Planet"
        className="absolute w-2/3 top-0 -right-40 opacity-20 rotating"
        style={{
          animation: "rotation 120s infinite linear",
          transformOrigin: "center center",
        }}
      />

      <style>
        {`
          @keyframes rotation {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(-360deg); }
          }
          
          .hover-card .card-content {
            opacity: 0;
            transition: all 0.3s ease;
          }
          
          .hover-card:hover .card-content {
            opacity: 1;
            background: rgba(0, 0, 0, 0.7);
          }
          
          .hover-card:hover .card-bg {
            transform: scale(1.1);
          }
          
          .action-button {
            transition: all 0.3s ease;
          }
          
          .action-button:hover {
            box-shadow: 0 0 20px rgba(72, 131, 184, 0.5);
          }
        `}
      </style>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 mt-40">
        <div className="mb-16 text-center">
          <h2 className="text-7xl font-bold text-white mb-6">
            Learning Hub for Modelling and Simulation
          </h2>
          <p className="text-xl text-white/90 mb-8 max-w-3xl mx-auto">
            A growing repository of domain specific training material in
            simulation and modeling. Made by researches for researchers, it
            features video recordings of lectures, slide navigation, and
            access to support material, code repositories, and exercises.
          </p>
          <div className="flex flex-wrap gap-6 justify-center">
            <Link to="/spaces" className="action-button flex items-center px-8 py-4 rounded-xl bg-gradient-to-r from-[#4883b8] to-sky-600 text-white text-lg font-semibold hover:bg-sky-600 transition transform hover:scale-105 no-underline">
              <img src={compass} alt="browse spaces" className="w-8 h-8 mr-3" />
              Browse spaces
            </Link>
            <Link to="https://alpha.lhumos.org/player/6/0/65a53f9de4b08f2db4344741/65b2180ae4b08f2db4352ab7" className="action-button flex items-center px-8 py-4 rounded-xl bg-gradient-to-r from-[#4883b8] to-sky-600 text-white text-lg font-semibold hover:bg-sky-600 transition transform hover:scale-105 no-underline">
              <img src={playicon} alt="Get to Know Lhumos" className="w-8 h-8 mr-3" />
              Get to Know Lhumos!
            </Link>
          </div>
        </div>

        <div className="backdrop-blur-lg bg-black/30 rounded-xl p-8 mb-12 shadow-xl">
          <h2 className="text-3xl font-bold text-white mb-4">
            Welcome to Lhumos Beta!
          </h2>
          <p className="text-lg text-white/90 mb-3">
            We're excited to have you try out our beta version. Your feedback is invaluable in helping us improve the platform.
          </p>
          <p className="text-lg text-white/90">
            Share your thoughts and suggestions at{" "}
            <a href="mailto:Lhumos@cecam.org" className="text-blue-300 hover:text-blue-200 transition-colors">
              Lhumos@cecam.org
            </a>
          </p>
        </div>

        <div className="backdrop-blur-lg bg-black/30 rounded-xl p-8 shadow-xl">
          <h2 className="text-3xl font-bold text-white mb-8">
            Funded by
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6">
            {[
              { logo: Cecamlogo, title: "CECAM", description: "Fostering computational science for societal progress" },
              { logo: Marvellogo, title: "MARVEL", description: "Design and discovery of novel materials" },
              { logo: Maxlogo, title: "MᴀX", description: "Enabling frontier HPC in the materials domain" },
              { logo: MultiLogo, title: "MultiXscale", description: "EuroHPC JU Centre of Excellence in multiscale modelling" },
              { logo: DomeLogo, title: "DOME", description: "An industrial data marketplace ecosystem based on Open Science and Open Innovation" }
            ].map((space, idx) => (
              <Link
                key={space.title}
                to={`/spaces/${idx}`}
                className="hover-card block rounded-xl overflow-hidden shadow-xl transition-all duration-300 transform hover:-translate-y-2 no-underline"
              >
                <div className="relative aspect-square">
                  <img
                    src={space.logo}
                    alt={`${space.title} logo`}
                    className="w-full h-full object-cover card-bg transition-transform duration-700"
                  />
                  <div className="card-content absolute inset-0 flex flex-col justify-end p-4 backdrop-blur-sm">
                    <h3 className="text-xl font-bold text-white mb-2">{space.title}</h3>
                    <p className="text-sm text-white/90">{space.description}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;